export const onlyNumbers = (value: string) => {
  return value.replace(/[^0-9]/g, '');
}

export const formatCPF = (value: string) => {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
}

export const formatCNPJ = (value: string) => {
  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
}

export const formatCellphone = (value: string) => {
  return value.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
}

export const formatCNPJInput = (cnpj: string) => {
  cnpj = cnpj.replace(/\D/g, '');
  cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4');
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5');
  return cnpj;
};

export const formatCPFOrCNPJInput = (input: string) => {
  let formattedInput = input.replace(/\D/g, '');
  
  if (formattedInput.length > 11) {
    formattedInput = formattedInput.replace(/^(\d{2})(\d)/, '$1.$2');
    formattedInput = formattedInput.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    formattedInput = formattedInput.replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4');
    formattedInput = formattedInput.replace(/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5');
  } else {
    formattedInput = formattedInput.replace(/^(\d{3})(\d)/, '$1.$2');
    formattedInput = formattedInput.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
    formattedInput = formattedInput.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4');
  }
  
  return formattedInput;
};

export const formatCellphoneInput = (cellphone: string) => {
  const cleaned = cellphone.replace(/\D/g, '');

  let formatted = '';
  for (let i = 0; i < cleaned.length; i++) {
    if (i === 0) {
      formatted += '(';
    } else if (i === 2) {
      formatted += ') ';
    } else if (i === 7) {
      formatted += '-';
    }
    formatted += cleaned[i];
  }

  return formatted;
};