import LayoutPage from '../LayoutPage';

const HomePage = () => {
  return (
    <LayoutPage>
      <>
      </>
    </LayoutPage>
  );
};

export default HomePage;