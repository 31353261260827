import { Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './../pages/LoginPage';
import HomePage from './../pages/HomePage';
import ChangePasswordPage from './../pages/ChangePasswordPage';
import AccessGroupPage from './../pages/AccessGroupPage';
import UsersPage from '../pages/UsersPage';
import useAuth from '../hooks/useAuth';
import UserPage from '../pages/UserPage';
import AccessGroupsPage from './../pages/AccessGroupsPage';
import { Permission } from '../types/Permission';
import CompaniesPage from './../pages/CompaniesPage';
import CompanyPage from './../pages/CompanyPage';
import CustomersPage from '../pages/CustomersPage';
import CustomerPage from '../pages/CustomerPage';

const RequireAuth = ({ children, permission }: { children: JSX.Element, permission?: Permission }) => {
  const { user, loading } = useAuth();

  if (loading)
    return <div>...</div>;

  if (!user)
    return <Navigate to="/login" />

  if (permission !== undefined && !user.permissions.includes(permission))
    return <Navigate to="/" />

  return children;
};

const RequireNonAuth = ({ children }: { children: JSX.Element }) => {
  const { user, loading } = useAuth();

  if (loading)
    return <div>...</div>;

  if (user)
    return <Navigate to="/" />

  return children;
};

const RoutesApp = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path='/login' element={<RequireNonAuth><LoginPage /></RequireNonAuth>}></Route>
      <Route path='/' element={<RequireAuth><HomePage /></RequireAuth>}></Route>
      <Route path='/changepassword' element={<RequireAuth><ChangePasswordPage /></RequireAuth>}></Route>
      <Route path='/accessgroups' element={<RequireAuth permission={Permission.ViewAccessGroups}><AccessGroupsPage /></RequireAuth>}></Route>
      <Route path='/accessgroup' element={<RequireAuth permission={Permission.ViewAccessGroups}><AccessGroupPage /></RequireAuth>}></Route>
      <Route path='/accessgroup/:id' element={<RequireAuth permission={Permission.ViewAccessGroups}><AccessGroupPage /></RequireAuth>}></Route>
      <Route path='/users' element={<RequireAuth permission={Permission.ViewUsers}><UsersPage /></RequireAuth>}></Route>
      <Route path='/user' element={<RequireAuth permission={Permission.ViewUsers}><UserPage /></RequireAuth>}></Route>
      <Route path='/user/:id' element={<RequireAuth permission={Permission.ViewUsers}><UserPage /></RequireAuth>}></Route>
      <Route path='/companies' element={<RequireAuth permission={Permission.ViewCompanies}><CompaniesPage /></RequireAuth>}></Route>
      <Route path='/company' element={<RequireAuth permission={Permission.ViewCompanies}><CompanyPage /></RequireAuth>}></Route>
      <Route path='/company/:id' element={<RequireAuth permission={Permission.ViewCompanies}><CompanyPage /></RequireAuth>}></Route>
      <Route path='/customers' element={<RequireAuth permission={Permission.ViewCustomers}><CustomersPage /></RequireAuth>}></Route>
      <Route path='/customer/:id' element={<RequireAuth permission={Permission.ViewCustomers}><CustomerPage /></RequireAuth>}></Route>
    </Routes>
  );
};

export default RoutesApp;