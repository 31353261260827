import axios from 'axios';
import PaginationRequest from '../types/PaginationRequest';
import PaginationResponse from '../types/PaginationResponse';
import AccessGroupPaginationResponse from '../types/AccessGroupPaginationResponse';
import LoginResponse from '../types/LoginResponse';
import ChangePasswordRequest from '../types/ChangePasswordRequest';
import UserPaginationResponse from '../types/UserPaginationResponse';
import UserResponse from '../types/UserResponse';
import CreateUserRequest from '../types/CreateUserRequest';
import UpdateUserRequest from '../types/UpdateUserRequest';
import CreateAccessGroupRequest from '../types/CreateAccessGroupRequest';
import UpdateAccessGroupRequest from '../types/UpdateAccessGroupRequest';
import PermissionResponse from '../types/PermissionResponse';
import AccessGroupResponse from '../types/AccessGroupResponse';
import CompanyPaginationResponse from '../types/CompanyPaginationResponse';
import CompanyResponse from '../types/CompanyResponse';
import CreateCompanyRequest from '../types/CreateCompanyRequest';
import UpdateCompanyRequest from '../types/UpdateCompanyRequest';
import CustomerPaginationResponse from '../types/CustomerPaginationResponse';
import CustomerResponse from '../types/CustomerResponse';
import UpdateCustomerRequest from '../types/UpdateCustomerRequest';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use(
  (config) => {
    //nprogress.start();
    const token = localStorage.getItem('TOKEN');
    if (token)
      config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    //nprogress.done();
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    //nprogress.done();
    return response;
  },
  (error) => {
    //nprogress.done();
    if (!error?.response)
      return Promise.reject('No connection with server.');

    if (error.response.status == 400)
      return Promise.reject(error.response.data.errors.join('<br/>'));

    if (error.response.status === 403)
      return Promise.reject('No authorized.'); // TODO: Retornar para o /home

    if (error.response.status === 401) {
      localStorage.clear();
      document.location.reload();
      return Promise.reject('No authenticated.');
    }

    return Promise.reject(`${error.response.status} - ${error.response.statusText}`);
  },
);

export const useApi = () => ({
  login: async (email: string, password: string) => {
    const response = await api.post<LoginResponse>('/users/login', { email, password });
    return response.data;
  },
  changePassword: async (request: ChangePasswordRequest) => {
    await api.put('/users/change-password', request);
  },
  getUserByPagination: async (request: PaginationRequest) => {
    const response = await api.get<PaginationResponse<UserPaginationResponse>>('/users/pagination', { params: request });
    return response.data;
  },
  getUserById: async (id: string) => {
    const response = await api.get<UserResponse>(`/users/${id}`);
    return response.data;
  },
  createUser: async (request: CreateUserRequest) => {
    const response = await api.post<string>('/users', request);
    return response.data;
  },
  updateUser: async (request: UpdateUserRequest) => {
    await api.put('/users', request);
  },
  getPermissions: async () => {
    const response = await api.get<PermissionResponse[]>('/permissions');
    return response.data;
  },
  getAccessGroupsByPagination: async (request: PaginationRequest) => {
    const response = await api.get<PaginationResponse<AccessGroupPaginationResponse>>('/access-groups/pagination', { params: request });
    return response.data;
  },
  getAccessGroupById: async (id: string) => {
    const response = await api.get<AccessGroupResponse>(`/access-groups/${id}`);
    return response.data;
  },
  createAccessGroup: async (request: CreateAccessGroupRequest) => {
    const response = await api.post<string>('/access-groups', request);
    return response.data;
  },
  updateAccessGroup: async (request: UpdateAccessGroupRequest) => {
    await api.put('/access-groups', request);
  },
  getCompaniesByPagination: async (request: PaginationRequest) => {
    const response = await api.get<PaginationResponse<CompanyPaginationResponse>>('/companies/pagination', { params: request });
    return response.data;
  },
  getCompanyById: async (id: string) => {
    const response = await api.get<CompanyResponse>(`/companies/${id}`);
    return response.data;
  },
  createCompany: async (request: CreateCompanyRequest) => {
    const response = await api.post<string>('/companies', request);
    return response.data;
  },
  updateCompany: async (request: UpdateCompanyRequest) => {
    await api.put('/companies', request);
  },
  getCustomersByPagination: async (request: PaginationRequest) => {
    const response = await api.get<PaginationResponse<CustomerPaginationResponse>>('/customers/pagination', { params: request });
    return response.data;
  },
  getCustomerById: async (id: string) => {
    const response = await api.get<CustomerResponse>(`/customers/${id}`);
    return response.data;
  },
  updateCustomer: async (request: UpdateCustomerRequest) => {
    await api.put('/customers', request);
  },
});