import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Form, Checkbox, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import CompanyResponse from '../../types/CompanyResponse';
import LayoutPage from '../LayoutPage';
import useAuth from '../../hooks/useAuth';
import { Permission } from '../../types/Permission';
import { APIType } from '../../types/APIType';
import { formatCNPJ, formatCNPJInput, onlyNumbers } from '../../services/format';

const CompanyPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const [company, setCompany] = useState<CompanyResponse>(null!);
  const { user } = useAuth();

  const handleSubmit = async () => {
    try {
      const payload = {
        ...company,
        cnpj: onlyNumbers(company.cnpj),
      };

      if (company?.id) {
        await api.updateCompany(payload);
        alert(t('recordSaveSuccess'));
        return;
      }

      await api.createCompany(payload);
      navigate('/companies');
    } catch (ex) {
      alert(ex);
    }
  };

  useEffect(() => {
    if (id)
      api.getCompanyById(id)
        .then((res) => {
          setCompany({
            ...res,
            cnpj: formatCNPJ(res.cnpj),
          });
        })
        .catch((res) => {
          alert(res);
          navigate('/company');
        });
  }, [id]);

  return (
    <LayoutPage>
      <Form layout='vertical'>
        <Form.Item label={t('name')}>
          <Input value={company?.name}
            onChange={(e) => setCompany({ ...company, name: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t('cnpj')}>
          <Input value={company?.cnpj}
            maxLength={18}
            onChange={(e) => setCompany({ ...company, cnpj: formatCNPJInput(e.target.value) })}
          />
        </Form.Item>
        <Form.Item label={t('apiType')}>
          <Select
            value={company?.apiType}
            onChange={(value) => setCompany({ ...company, apiType: value })}
          >
            <Select.Option key={APIType.Biagre} value={APIType.Biagre}>Biagre</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('apiUrl')}>
          <Input value={company?.apiUrl}
            onChange={(e) => setCompany({ ...company, apiUrl: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t('apiToken')}>
          <Input value={company?.apiToken}
            onChange={(e) => setCompany({ ...company, apiToken: e.target.value })}
          />
        </Form.Item>
        {company?.id && (<Form.Item>
          <Checkbox
            checked={company?.inactive}
            onChange={(e) => setCompany({ ...company, inactive: e.target.checked })}
          >
            {t('inactive')}
          </Checkbox>
        </Form.Item>
        )}
        {user?.permissions.includes(Permission.ManageCompanies) &&
          <Form.Item>
            <Button type='primary' onClick={handleSubmit}>{t('save')}</Button>
          </Form.Item>
        }
      </Form>
    </LayoutPage>
  )
};

export default CompanyPage;