import { useTranslation } from 'react-i18next';
import { useApi } from '../../hooks/useApi';
import { useEffect, useState } from 'react';
import { Table, Space, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CustomerPaginationResponse from '../../types/CustomerPaginationResponse';
import { ColumnsType } from 'antd/es/table';
import LayoutPage from '../LayoutPage';
import { formatCNPJ, formatCPF, formatCellphone } from '../../services/format';

const CustomersPage = () => {
  const { t } = useTranslation();
  const api = useApi();
  const [customers, setCustomers] = useState<CustomerPaginationResponse[]>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const columns: ColumnsType<CustomerPaginationResponse> = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <a onClick={() => navigate(`/customer/${id}`)}>{id}</a>,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('cpfcnpj'),
      dataIndex: 'cpfcnpj',
      key: 'cpfcnpj',
      render: (cpfcnpj: string) => cpfcnpj.length == 11 ?  formatCPF(cpfcnpj) : formatCNPJ(cpfcnpj),
    },
    {
      title: t('cellphone'),
      dataIndex: 'cellphone',
      key: 'cellphone',
      render: (cellphone: string) => formatCellphone(cellphone),
    },
    {
      title: t('registerDate'),
      dataIndex: 'registerDate',
      key: 'registerDate',
      render: (registerDate: string) => moment(registerDate).format('DD/MM/yyyy HH:mm:ss'),
    },
    {
      title: t('status'),
      dataIndex: 'inactive',
      key: 'inactive',
      render: (inactive: boolean) => <Tag color={inactive ? 'red' : 'green'}>{inactive ? t('inactive') : t('active')}</Tag>,
    },
  ];

  useEffect(() => {
    setLoading(true);
    api.getCustomersByPagination({ take: 1000, orderColumn: 'registerDate', orderDescending: true })
      .then(res => {
        setCustomers(res.data);
      })
      .catch((ex) => {
        alert(ex);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <LayoutPage>
      <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
        <Table
          columns={columns}
          dataSource={customers}
          loading={loading}
          pagination={false}
        />
      </Space>
    </LayoutPage>
  )
};

export default CustomersPage;