import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Form, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomerResponse from '../../types/CustomerResponse';
import LayoutPage from '../LayoutPage';
import useAuth from '../../hooks/useAuth';
import { Permission } from '../../types/Permission';
import { formatCPF, formatCPFOrCNPJInput, formatCellphone, formatCellphoneInput, onlyNumbers, formatCNPJ } from '../../services/format';

const CustomerPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState<CustomerResponse>(null!);
  const { user } = useAuth();

  const handleSubmit = async () => {
    try {
      await api.updateCustomer({
        ...customer,
        cpfcnpj: onlyNumbers(customer.cpfcnpj),
        cellphone: onlyNumbers(customer.cellphone),
      });
      alert(t('recordSaveSuccess'));
    } catch (ex) {
      alert(ex);
    }
  };

  useEffect(() => {
    if (id)
      api.getCustomerById(id)
        .then((res) => {
          setCustomer({
            ...res,
            cpfcnpj: res.cpfcnpj.length == 11 ? formatCPF(res.cpfcnpj) : formatCNPJ(res.cpfcnpj),
            cellphone: formatCellphone(res.cellphone),
          });
        })
        .catch((res) => {
          alert(res);
          navigate('/customer');
        });
  }, [id]);

  return (
    <LayoutPage>
      <Form layout='vertical'>
        <Form.Item label={t('name')}>
          <Input value={customer?.name}
            onChange={(e) => setCustomer({ ...customer, name: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t('cpfcnpj')}>
          <Input value={customer?.cpfcnpj}
            maxLength={18}
            onChange={(e) => setCustomer({ ...customer, cpfcnpj: formatCPFOrCNPJInput(e.target.value) })}
          />
        </Form.Item>
        <Form.Item label={t('cellphone')}>
          <Input value={customer?.cellphone}
            maxLength={15}
            onChange={(e) => setCustomer({ ...customer, cellphone: formatCellphoneInput(e.target.value) })}
          />
        </Form.Item>
        {customer?.id && (<Form.Item>
          <Checkbox
            checked={customer?.inactive}
            onChange={(e) => setCustomer({ ...customer, inactive: e.target.checked })}
          >
            {t('inactive')}
          </Checkbox>
        </Form.Item>
        )}
        {user?.permissions.includes(Permission.ManageCustomers) &&
          <Form.Item>
            <Button type='primary' onClick={handleSubmit}>{t('save')}</Button>
          </Form.Item>
        }
      </Form>
    </LayoutPage>
  )
};

export default CustomerPage;